import { ArtworkConfigLocal } from "../models";

export const artworksConfig: ArtworkConfigLocal[] = [
  {
    id: 99,
    title: "Middle happy cloud 1",
    year: "2022",
    author: "Rachel De Joode",
    imageUrl:
      "https://storage.googleapis.com/artwork_images/upload_fe7f1ab08ad55ecae118038ec976efb6.jpg",
    videoUrl:
      "https://storage.googleapis.com/artwork_videos/upload_945bf17193637dac153187b525bef460.mp4",
  },
  {
    id: 100,
    title: "Middle happy cloud 2",
    year: "2022",
    author: "Rachel De Joode",
    imageUrl:
      "https://storage.googleapis.com/artwork_images/upload_3e4c1d2761bd66f5e0e0a8a9e959ee3f.jpg",
    videoUrl:
      "https://storage.googleapis.com/artwork_videos/upload_95322124c9c032c6dd4e0527dd78f166.mp4",
  },
  {
    id: 101,
    title: "Middle happy cloud 3",
    year: "2022",
    author: "Rachel De Joode",
    imageUrl:
      "https://storage.googleapis.com/artwork_images/upload_605130908eb8fd0cfaf10f8f9705131e.jpg",
    videoUrl:
      "https://storage.googleapis.com/artwork_videos/upload_1cbbc6ad3de426cd0c6862713b54ae91.mp4",
  },
  {
    id: 102,
    title: "Middle happy cloud 4",
    year: "2022",
    author: "Rachel De Joode",
    imageUrl:
      "https://storage.googleapis.com/artwork_images/upload_b218317a29468fba25b7dd3c646b87e2.jpeg",
    videoUrl:
      "https://storage.googleapis.com/artwork_videos/upload_61f06d578236ed1ec37d618932548986.mp4",
  },
];
